import { isDevelopment } from "wcz-layout";

let apiUrl: string = "https://api.dev.wistron.eu/";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/";
}

//export microservices base URL
export { apiUrl };
