import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import ShipmentListDto from '../../models/ShipmentListDto';
import { uniq } from 'lodash';
import { Card, CardContent, Theme, Typography } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'inline-block',
            marginLeft: '1rem',
            marginTop: '1rem',            
        },
        title: {
            fontSize: 14,
        },
        fade: {
            opacity: 0.3,
        }
    })
);

interface AoTilePanelProps {
    shipments: ShipmentListDto[],
    selectedAos: string[],
    setSelectedAos: (selectedAos: string[]) => void, 
}

export default function AoTilePanel(props: AoTilePanelProps) {
    const classes = useStyles();
    const [aos, setAos] = useState([] as string[]);

    useEffect(() => {
        if (props.shipments) {
            setAos(uniq(props.shipments.map((el: any) => el.ao)));
        }
    }, [props.shipments]);  

    const toggleSelectedAos = (clickedAo: string) => {
        if (props.selectedAos.includes(clickedAo)) {
            props.setSelectedAos(props.selectedAos.filter(ao => ao !== clickedAo));
        }
        else {
            props.setSelectedAos([clickedAo, ...props.selectedAos]);
        }    
    };

    if (aos.length < 2) return null;

    return (
        <React.Fragment>
            {aos.map((ao: string) => {
                return (
                    <Card className={classes.root} onClick={() => toggleSelectedAos(ao)}>
                        <CardContent className={props.selectedAos.includes(ao) ? "" : classes.fade}>
                        <Typography sx={{ fontSize: 11, mb: 1 }}>{props.shipments.find(f => f.ao === ao)?.plannedShipmentDate}</Typography>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {ao}
                            </Typography>
                            <Typography variant="h6" component="h2">
                                {props.shipments.filter(f => f.hasMarkup && f.ao === ao).length + " / " + props.shipments.filter(f => f.ao === ao).length}
                            </Typography>
                        </CardContent>
                    </Card>
                );
            })}
        </React.Fragment>
    );
}