import { BrowserRouter, Route, Routes } from 'react-router-dom';
import packageJson from '../package.json';

//Contexts
import { LayoutProvider, LeftDrawerItem } from 'wcz-layout';

//Pages
import HomePage from './pages/HomePage';
import { useMemo } from 'react';
import { Home } from '@mui/icons-material';

export default function App() {
    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> }
    ], []);
    
    return (
        <BrowserRouter>
                <LayoutProvider title={packageJson.name} appVersion={packageJson.version} primaryColor={"#C67922"} secondaryColor={"#FFE39F"} leftDrawerItems={leftDrawerItems}>
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                    </Routes>
                </LayoutProvider>
        </BrowserRouter>
    );
}