import React, { useContext, useEffect, useState } from 'react';
// @ts-ignore
import { uniq } from 'lodash';

import SearchUsnTextField from '../components/home/SearchUsnTextField';
import ShipmentListDto from '../models/ShipmentListDto';
import { fetchPost, isMobile } from 'wcz-layout';
import AoTilePanel from '../components/home/AoTilePanel';
import { fetchGet, LayoutContext } from 'wcz-layout';
import { useQuery } from '@tanstack/react-query';
import { Grid } from '@mui/material';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { apiUrl } from '../utils/BaseUrl';


export default function Home() {
    const { user, changeTitle } = useContext(LayoutContext);
    const [shipments, setShipments] = useState([] as ShipmentListDto[]);
    const [lastScanned, setLastScanned] = useState<ShipmentListDto>();
    const [selectedAos, setSelectedAos] = useState([] as string[]);

    useQuery<ShipmentListDto[]>(["shipments", "unfinished"], ({ signal }) => fetchGet(apiUrl + "wh-fg-preparation/v1/shipment/unfinished", signal), 
        {
            onSuccess: data => {
                setShipments(data);   
                setSelectedAos(uniq(data.map((el: any) => el.ao)));       
            },
            onError: () => alert("je tam nejaky error!")
        });

    useEffect(() => {
        changeTitle("Shipment Overview");
        // eslint-disable-next-line
    }, [user]);    

    useEffect(() => {
        if (lastScanned) {
            const dto = {
                usn: lastScanned.usn,
                dateCreated: Date.now().toString(),
                createdBy: user.name + " (" + user.id + ")"
            };
    
            fetchPost(apiUrl + "wh-fg-preparation/v1/shipment/markup", dto);
        }        
        // eslint-disable-next-line
    }, [lastScanned]);

    const columns = [
        {
            name: "ao",
            label: "AO#",
        },
        {
            name: "pn",
            label: "PN#",
        },
        {
            name: "description",
            label: "Description",
            options: {
                display: !isMobile
            }
        },
        {
            name: "quantity",
            label: "Qty",
            options: {
                display: !isMobile
            }
        },
        {
            name: "mo",
            label: "MO#",
            options: {
                display: !isMobile
            }
        },
        {
            name: "dn",
            label: "DN#",
            options: {
                display: !isMobile
            }
        },
        {
            name: "usn",
            label: "SN#",
        },
        {
            name: "hasMarkup",
            label: "Highlighted",
            options: {
                display: false            
            }
        },
        {
            name: "plannedShipmentDate",
            label: "Shipment date"
        }
    ];
    
    const options: MUIDataTableOptions = {
        selectableRows: "none",
        pagination: true,
        filter: true,
        print: false,
        download: true,
        viewColumns: false,
        responsive: "standard",
        tableBodyMaxHeight: "72vh",
        setTableProps: () => { return { size: "small" }; },
        setRowProps: (row: any) => {
            if (row[7] === true)
                return { style: { background: "lightgreen"} };
            else 
                return { style: { background: "inherit"} };
        }
    };

    return (
        <React.Fragment>            
            <Grid container sx={{ flexGrow: 1, px:2, pt: 2 }}>
                <Grid item xs={12} md={3}>
                    <SearchUsnTextField shipments={shipments} setShipments={setShipments} setLastScanned={setLastScanned} selectedAos={selectedAos} />
                </Grid>  
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <AoTilePanel shipments={shipments} selectedAos={selectedAos} setSelectedAos={setSelectedAos} />
                </Grid>
            </Grid>

            <Grid item sx={{ m:2 }}>
                <MUIDataTable
                    title="Today's shipment"
                    data={shipments}
                    columns={columns}
                    options={options}
                />            
            </Grid>
        </React.Fragment>
    );
}