import React, { useState, useEffect } from 'react';
import { SearchResult } from '../../models/enums/SearchResult';
import ShipmentListDto from '../../models/ShipmentListDto';
import { Check, Close, Search } from '@mui/icons-material';
import { InputAdornment, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchResultOk: {
        '& label': {
            color: "green !important",
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: "green !important",
            }
        },
        '& .MuiFormHelperText-root': {
            color: "green !important"
        }
    },
  })
);

const searchIcon = (
    <Search color="primary"/>
);

const checkIcon = (
    <Check style={{ color: "green" }}/>
);

const closeIcon = (
    <Close color="error"/>
);

interface SearchUsnTextFieldProps {
    shipments: ShipmentListDto[],
    setShipments: (shipments: ShipmentListDto[]) => void, 
    setLastScanned: (shipment: ShipmentListDto) => void,
    selectedAos: string[], 
}

export default function SearchUsnTextField(props: SearchUsnTextFieldProps) {
    const classes = useStyles();
    const [searchedUsn, setSearchedUsn] = useState("");
    const [searchResult, setSearchResult] = useState(SearchResult.Init);
    const [inputAdornment, setInputAdornment] = useState(searchIcon); 
    const [inputHelperText, setInputHelperText] = useState("");   
    const [inputHasError, setInputHasError] = useState(false);   
    const [inputClass, setInputClass] = useState(""); 

    useEffect(() => {
        switch (searchResult) {
            case SearchResult.Init:
                setInputAdornment(searchIcon);
                setInputHelperText("");
                setInputHasError(false);
                setInputClass("");
                break;
            case SearchResult.Ok:
                setInputAdornment(checkIcon);
                let ao = props.shipments.find(item => item.usn === searchedUsn)?.ao ?? "n/a";
                setInputHelperText("Scanned serial number successfully matched (AO#: " + ao + ").");
                setInputHasError(false);
                setInputClass(classes.searchResultOk);
                new Audio("/sounds/SOUND_OK.wav").play();
                break;
            case SearchResult.NotFound:
                setInputAdornment(closeIcon);
                setInputHelperText("Scanned serial number not found in selected AO(s)!");
                setInputHasError(true);
                setInputClass("");
                new Audio("/sounds/SOUND_NG.wav").play();
                break;
        }

    // eslint-disable-next-line
    }, [searchResult]);

    const findUsn = () => {
        let shipment = props.shipments.find(item => item.usn === searchedUsn && props.selectedAos.includes(item.ao));      

        if (shipment) {
            setSearchResult(SearchResult.Ok);

            shipment.hasMarkup = true;
            props.setLastScanned(shipment);
            props.setShipments([shipment, ...props.shipments.filter(f => f.usn !== shipment?.usn)]);
        }
        else {
            setSearchResult(SearchResult.NotFound);            
        }       
    };

    const onKeyDownHandler = (e: any) => {
        if (searchResult === SearchResult.NotFound || searchResult === SearchResult.Ok)
            setSearchedUsn("");

        if (e.key === 'Enter') findUsn();
    };

    return (
        <React.Fragment>
            <TextField                    
                id="txtSearchUsn"
                variant="outlined"
                fullWidth 
                focused  
                autoFocus   
                label={"Scan & search by USN: " + props.shipments.filter(f => f.hasMarkup).length + " / " + props.shipments.length}
                InputLabelProps={{ shrink: true }}
                className={inputClass}  
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {inputAdornment}
                        </InputAdornment>
                    ),
                }} 
                value={searchedUsn}
                helperText={inputHelperText}
                error={inputHasError}
                onChange={(e: any) => { setSearchedUsn(e.target.value); setSearchResult(SearchResult.Init); }}
                onKeyDown={onKeyDownHandler}                                                      
            />
        </React.Fragment>
    );
}


